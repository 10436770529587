export default {
    login             : '/login',
    home              : '/home',
    pieceList         : '/piece-list',
    orders            : {
      ordersList     : '/orders',
      ordersDetailId : '/orders/:id'
    },
    order             : '/orders',
    editUser          : '/editUser',
    signup            : '/signup',
    signupId          : '/signup/:id?',
    verify            : '/verify',
    resetPassword     : '/reset-password',
    privacy           : '/politica-de-privacidad',
    legal             : '/aviso-legal',
    cookies           : '/politica-de-cookies',
    contact           : '/contact',
    sellConditions    : '/sell-conditions',
    ecommerce         : {
      shoppingCart    : '/shopping-cart'
    },
    product                      : {
      errorCode                  : '/product/:id/error-code',
      errorCodeDetail            : '/product/:id/error-code/:idError',
      troubleshootingErrorCode   : '/product/:id/error-code/:idError/troubleshooting',
      errorCodeNo                : '/product/:id/error-code-no',
      errorCodeNoDetail          : '/product/:id/error-code-no/:idError',
      troubleshootingErrorCodeNo : '/product/:id/error-code-no/:idError/troubleshooting',
      detail                     : '/product',
      detailId                   : '/product/:id',
    },
    questionErrorCode : {
      detail          : '/question-error-code',
      detailId        : '/question-error-code/:id',
    },
    questionErrorCodeNo : {
      detail          : '/question-error-code-no',
      detailId        : '/question-error-code-no/:id',
    }
};
  