import React, {useContext} from 'react';
import pageProvider from "../../providers/pageProvider";

import './BarMatter.scss'

import { withTranslation, Trans } from "react-i18next";

function BarMatter() {

    const [page] = useContext(pageProvider);

    return (
        // <div>
            <div className="container-fluid bg-primary">
                {   page && page.translate !== '' &&
                    <div className="container">
                        <h6 className="p-3 barmatter-title">
                            <Trans i18nKey={page.translate}></Trans> 
                            {page.code ? ` | ${page.code}` : ''}
                        </h6>
                    </div>
                }
            </div>
            // <div>
            //     <div className="container-fluid" style={{backgroundColor: '#ffe5a2'}}>
            //         <div className="container">
            //             <div className='p-3'>
            //                 <span>
            //                     <Trans i18nKey="availability-message" />
            //                 </span>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        // </div>
      );

}
export default withTranslation()(BarMatter);