export const allowedSubdomains = [
  'haierhvac',
  'haier',
  'hec',
  'airmart',
  'noname',
  'wind',
  'all-brand'
];

let currentDomain = window.location.host;

let tmpCurrentSubdomain = '';
if (currentDomain.includes('localhost')) {
  tmpCurrentSubdomain = 'haier';
} else {
  tmpCurrentSubdomain = currentDomain;
}


export const currentSubdomain = tmpCurrentSubdomain;
