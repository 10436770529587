import React, { useState, useEffect, useContext } from 'react';
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import { withTranslation, WithTranslation, Trans, useTranslation } from 'react-i18next';

// Styles
import '../../styles/components/header.css'

// Services
import StorageAuth from '../../services/storage/Auth';
import StorageUser from '../../services/storage/User';
import StorageCompany from '../../services/storage/Company';
import RoutePathLibrary from '../../utils/RoutePath';
import { useHistory } from "react-router-dom";

import DropdownLanguage from "../Common/DropdownLanguage";
import orderProvider from "../../providers/orderProvider";
import cssProvider from "../../providers/cssProvider";

import Pulse from 'react-reveal/Pulse';


function Header(props:any) {

    const [orderList] = useContext(orderProvider);
    const [_css] = useContext(cssProvider);
  	const [numPieces, setNumPieces] = useState(0);
    let history = useHistory();

    const name      = localStorage.getItem('user.name') || '';
    const surname   = localStorage.getItem('user.surname') || '';
    const company   = localStorage.getItem('company.name') || '';
    const arrName   = [name, surname];

    const [isOpen, setIsOpen] = useState(false);
    const [initials, setInitials] = useState('');
    const [moveTada, setMoveTada] = useState(1);

    useEffect(() => {
      let buildingInitials = '';
      for (let i = 0; i < arrName.length; i++) {
        buildingInitials += arrName[i].substr(0,1).toUpperCase();      
      }
      setInitials(buildingInitials);

    }, [])

    useEffect(() => {
      let tmpNumPieces = 0;
      for (let i = 0; i < orderList.length; i++) {
        tmpNumPieces = tmpNumPieces + orderList[i].quantitySelected;
      }
      setNumPieces(tmpNumPieces);
      setMoveTada(tmpNumPieces);
    },[orderList])
  
    const handleCollapseClick = () => {
      setIsOpen(!isOpen);
    }

    const logOut = (e:any) => {
      StorageUser.removeUser();
      StorageAuth.removeSession();
      StorageCompany.removeCompany();
  
      window.location.href =  RoutePathLibrary.login;
      return;
    }

    const editUser = () => {
      handleCollapseClick();
      history.push(`${RoutePathLibrary.editUser}`)
    }

    const shoppingCart = () => {
      if (orderList.length > 0){
        history.push(`${RoutePathLibrary.ecommerce.shoppingCart}`)
      }
    }

    const gotohome = () => {
      history.push(`${RoutePathLibrary.home}`)
    }
  
  // Mobile Menu
  return (
    <Navbar bg="white" expand="lg">
      <Navbar.Brand onClick={gotohome}>
        <img className="img-fluid cursor-pointer" style={{maxWidth: '130px'}} src={_css ? _css.pathImg : ''} />
      </Navbar.Brand>
      <a onClick={shoppingCart} className="position-relative navbar-toggler" style={{ marginLeft: 'auto', marginRight: '10px', border: 'none' }}>
        <i className="fas fa-shopping-cart" style={{fontSize: '1.5em', color: 'rgba(0,0,0,.5)' }} ></i>
        { numPieces > 0 &&
          <Pulse spy={moveTada} delay={0}>
            <span className="notification-order" style={{ marginLeft: '10px' }}>{numPieces}</span>
          </Pulse>
        }
      </a>
      <button onClick={handleCollapseClick} className="navbar-toggler">
        { !isOpen ?
          <i className="fas fa-align-justify"></i> :
          <i className="fas fa-times p-1"></i>
        }
      </button>
      { isOpen &&
        <div className="navbar-collapse-custom show">
          <div className="ml-auto navbar-nav">
            <a className="text-center dropdown-item">
              <b className="color-primary">{name} {surname}</b> <br/>
              <small>{company}</small>
            </a>
            <hr className="col-8 text-center bg-white"/>
            <div className="text-center">
              <a href="/" data-rb-event-key="#home" className="nav-link mb-4">
                <b><Trans i18nKey="Header.Link1"></Trans></b>
              </a>
              <a href={RoutePathLibrary.pieceList} className="nav-link mb-4">
                <b><Trans i18nKey="Header.Link2"></Trans></b>
              </a>
              <a href={RoutePathLibrary.orders.ordersList} className="nav-link mb-4">
                <b><Trans i18nKey="Header.Link3"></Trans></b>
              </a>
              <a onClick={editUser} data-rb-event-key="#home" className="nav-link mb-4">
                <b><Trans i18nKey="Header.Dropdown.item1"></Trans></b>
              </a>
              <a onClick={logOut} data-rb-event-key="#home" className="nav-link mb-4">
                <b><Trans i18nKey="Header.Dropdown.item2"></Trans></b>
              </a>
              <DropdownLanguage />
            </div>
          </div>
        </div>
      }
      {/* Desktop menu */}
      <Navbar.Collapse bsPrefix="navbar-collapse" id="basic-navbar-nav">
        <Nav className="ml-auto" style={{alignItems: 'center'}}>
          <div className="mr-4 line-height-lg-3 nav-link">
            <DropdownLanguage />
          </div>
          <Nav.Link href="/" className="mr-4 line-height-lg-3">
            <b><Trans i18nKey="Header.Link1"></Trans></b>
          </Nav.Link>
          <Nav.Link href={RoutePathLibrary.pieceList} className="mr-4 line-height-lg-3">
            <b><Trans i18nKey="Header.Link2"></Trans></b>
          </Nav.Link>
          <Nav.Link href={RoutePathLibrary.orders.ordersList} className="mr-4 line-height-lg-3">
            <b><Trans i18nKey="Header.Link3"></Trans></b>
          </Nav.Link>
          <Nav.Link href="" onClick={shoppingCart} className="mr-4 line-height-lg-3">
            <div className="position-relative">
              <i className="fas fa-shopping-cart" style={{fontSize: '1.5em', color: 'rgba(0,0,0,.5)' }}></i>
              { numPieces > 0 &&
                <Pulse spy={moveTada} delay={0}>
                  <span className="notification-order">{numPieces}</span>
                </Pulse>
              }
            </div>
          </Nav.Link>
          <NavDropdown className="userClass animated fadeIn color-haier" title={initials} id="basic-nav-dropdown">
            <NavDropdown.Item className="text-center">
              <b>{name} {surname}</b> <br/>
              <span>{company}</span>
            </NavDropdown.Item>
            <hr className="col-8 text-center bg-white" />
            <NavDropdown.Item onClick={editUser} >
              <i className="fas fa-user mr-2"></i>
              <Trans i18nKey="Header.Dropdown.item1"></Trans>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logOut} >
              <i className="fas fa-sign-out-alt mr-2"></i>
              <Trans i18nKey="Header.Dropdown.item2"></Trans>              
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );

}

export default withTranslation()(Header);
