import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom';

import PageLoader from './components/Common/PageLoader';

import BaseEmpty from './components/Layout/BaseEmpty';
import Base from './components/Layout/Base';

import StorageAuth from './services/storage/Auth';
import AuthInterceptor from './services/AuthInterceptor';
import RoutePathLibrary from './utils/RoutePath';

AuthInterceptor();

/* Used to render a lazy component with react-router */
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props}/>;

const LoginForm         = lazy(() => import('./components/Login/LoginForm'));
const Signup            = lazy(() => import('./components/Login/Signup'));
const Verify            = lazy(() => import('./components/Verify/VerifyUser'));
const Home              = lazy(() => import('./components/Home/Home'));
const EditUser          = lazy(() => import('./components/EditUser/FormEditUser'));
const ProductDetail     = lazy(() => import('./components/Product/DetailProduct'));
const ListErrorsCode    = lazy(() => import('./components/ErrorCode/ListErrorsCode'));
const ListErrorsCodeNo  = lazy(() => import('./components/ErrorCodeNo/ListErrorsCodeNo'));
const DetailErrorCode   = lazy(() => import('./components/ErrorCode/DetailErrorCode'));
const DetailErrorCodeNo = lazy(() => import('./components/ErrorCodeNo/DetailErrorCodeNo'));
const TroubleshootingErrorCodeNo = lazy(() => import('./components/ErrorCodeNo/TroubleshootingErrorCodeNo'));
const ShoppingCart      = lazy(() => import('./components/Ecommerce/ShoppingCart'));
const PieceList         = lazy(() => import('./components/Piece/PieceList'));
const OrderList         = lazy(() => import('./components/Order/OrderList'));
const OrderDetail       = lazy(() => import('./components/Order/OrderDetail'));
// const Privacy           = lazy(() => import('./components/Common/Policies/Privacy'));
// const Legal             = lazy(() => import('./components/Common/Policies/Legal'));
// const Cookies           = lazy(() => import('./components/Common/Policies/Cookies'));

const Contact           = lazy(() => import('./components/Common/Policies/Contact'));
const SellConditions    = lazy(() => import('./components/Common/Policies/SellConditions'));

const Routes = ({ location }: RouteProps ) => {
    const isLoggedIn = StorageAuth.isTokenAlive(StorageAuth.getSession());
    if (!isLoggedIn) {
        if (location && location.pathname.indexOf('/signup') === 0){
            return (
                // Page Layout component wrapper
                <BaseEmpty>
                    <Suspense fallback={<PageLoader/>}>
                        <Switch location={location}>
                            <Route path={ RoutePathLibrary.signupId } component={waitFor(Signup)}/>
                        </Switch>
                    </Suspense>
                </BaseEmpty>
            );
        } else if (location?.pathname === '/verify'){
            return (
                // Page Layout component wrapper
                <BaseEmpty>
                    <Suspense fallback={<PageLoader/>}>
                        <Switch location={location}>
                            <Route path={ RoutePathLibrary.verify } component={waitFor(Verify)}/>
                        </Switch>
                    </Suspense>
                </BaseEmpty>
            );
        } else {
            return (
                // Page Layout component wrapper
                <BaseEmpty>
                    <Suspense fallback={<PageLoader/>}>
                        <Switch location={location}>
                            <Route path={ RoutePathLibrary.login } component={waitFor(LoginForm)}/>
                            <Route path={ RoutePathLibrary.contact } component={waitFor(Contact)}/>
                            <Route path={ RoutePathLibrary.sellConditions } component={waitFor(SellConditions)}/>
                            <Redirect to={ RoutePathLibrary.login } />
                        </Switch>
                    </Suspense>
                </BaseEmpty>
            );
        }
    }
    if (location?.pathname === '/verify'){
        return (
            // Page Layout component wrapper
            <BaseEmpty>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        <Route path={ RoutePathLibrary.verify } component={waitFor(Verify)}/>
                    </Switch>
                </Suspense>
            </BaseEmpty>
        );
    }
    return (
        // Layout component wrapper
        <Base>
          <Suspense fallback={<PageLoader/>}>
            <Switch location={location}>
                <Route path={ RoutePathLibrary.home } component={waitFor(Home)}/>
                <Route path={ RoutePathLibrary.pieceList } component={waitFor(PieceList)}/>
                <Route path={ RoutePathLibrary.orders.ordersDetailId } component={waitFor(OrderDetail)}/>
                <Route path={ RoutePathLibrary.orders.ordersList } component={waitFor(OrderList)}/>
                <Route path={ RoutePathLibrary.product.troubleshootingErrorCode } component={waitFor(DetailErrorCode)}/>
                <Route path={ RoutePathLibrary.product.troubleshootingErrorCodeNo } component={waitFor(TroubleshootingErrorCodeNo)}/>
                <Route path={ RoutePathLibrary.product.errorCodeDetail } component={waitFor(DetailErrorCode)}/>
                <Route path={ RoutePathLibrary.product.errorCodeNoDetail } component={waitFor(DetailErrorCodeNo)}/>
                <Route path={ RoutePathLibrary.product.errorCode } component={waitFor(ListErrorsCode)}/>
                <Route path={ RoutePathLibrary.product.errorCodeNo } component={waitFor(ListErrorsCodeNo)}/>
                <Route path={ RoutePathLibrary.product.detailId } component={waitFor(ProductDetail)}/>
                <Route path={ RoutePathLibrary.editUser } component={waitFor(EditUser)}/>
                <Route path={ RoutePathLibrary.ecommerce.shoppingCart } component={waitFor(ShoppingCart)}/>
                <Route path={ RoutePathLibrary.contact } component={waitFor(Contact)}/>
                <Route path={ RoutePathLibrary.sellConditions } component={waitFor(SellConditions)}/>
                <Redirect to={ RoutePathLibrary.home }/>
                </Switch>
            </Suspense>
        </Base>
    )
}

export default withRouter(Routes);
