import React, { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import RoutePath from '../../utils/RoutePath';
import CssProvider from "../../providers/cssProvider";

import '../../styles/components/footer.css'
import { useHistory } from 'react-router-dom';
// import LanguageProvider from '../../providers/languageProvider';
import HttpStorageI18n from "../../services/storage/Language";

const Footer = () => {

    const { t, i18n  } = useTranslation();
    const [_css] = useContext(CssProvider);
    let history = useHistory();

    const handleOpen = (type: string) => {
        switch (type) {
            case 'privacy':
                window.open(t(`${_css.brand.toLowerCase()}-privacy`), '_blank')
                break;      
            case 'legal':
                window.open(t(`${_css.brand.toLowerCase()}-legal`), '_blank')
                break;
            case 'cookies':
                window.open(t(`${_css.brand.toLowerCase()}-cookies`), '_blank')
                break;
            case 'contact':
                history.push(`${RoutePath.contact}`);
                break;
            case 'sell-conditions':
                history.push(`${RoutePath.sellConditions}`);
                break;
        }

    }

    return (
        <div className="container-fluid text-center text-white footer bg-primary">
            <div className="row">
                <div className="col-12">
                    <a className="mr-2 ml-2 cursor-pointer" onClick={() => handleOpen('contact')}>{t('contact')}</a>|
                    { i18n.exists('sell-conditions') ?
                        <span>
                            <a className="mr-2 ml-2 cursor-pointer" onClick={() => handleOpen('sell-conditions')}>{t('sell-conditions')}</a>|
                        </span>
                        : ''
                    }
                    <a className="mr-2 ml-2 cursor-pointer" onClick={() => handleOpen('privacy')}>{t('privacy')}</a>|
                    <a className="mr-2 ml-2 cursor-pointer" onClick={() => handleOpen('legal')}>{t('legal')}</a>|
                    <a className="mr-2 ml-2 cursor-pointer" onClick={() => handleOpen('cookies')}>{t('cookies')}</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;