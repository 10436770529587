import React, { useState, useContext } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown  } from 'reactstrap';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';


import { languagesList } from "../../utils/Language";
import HttpStorageI18n from "../../services/storage/Language";

import LanguageProvider from "../../providers/languageProvider";

import "../../styles/components/dropdown-language.css";

function DropdownLanguage(props: WithTranslation) {

    const [currentLanguage, setCurrentLanguage] = useContext(LanguageProvider);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { t } = useTranslation();
    
    const toggle = () => setDropdownOpen(prevState => !prevState);

    function changeValue(acronym:string) {
        setCurrentLanguage(acronym);
        HttpStorageI18n.saveLanguage(acronym);
        props.i18n.changeLanguage(acronym);
    };

  return (
    <div>
        <UncontrolledDropdown>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle caret color="primary">
                {
                    t(languagesList[languagesList.findIndex( (item:any) => item.acronym === currentLanguage)].title)
                }
                </DropdownToggle>
                <DropdownMenu className="DropdownMenu-Language">
                {
                    languagesList.map( (el:any) => <DropdownItem onClick={() => changeValue(el.acronym)} key={el.acronym} >{t(el.title)} </DropdownItem> )
                }
                </DropdownMenu>
            </Dropdown>
        </UncontrolledDropdown>
    </div>
  );

}
export default withTranslation()(DropdownLanguage);