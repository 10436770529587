import Axios from 'axios';

import StorageAuth from './../services/storage/Auth';
import StorageUser from './../services/storage/User';
import Auth from './http/Auth';
import Notification from '../components/Elements/Notification';

// TODO Maybe convert to publicAPIPaths (and invert logic)?
const privateAPIPaths = [
  '/user',
  '/ecommerce',
  '/piece',
  '/brand',
  '/search',
  '/product',
  '/order',
  '/stripe',
];

function isIncludedInPrivateAPIPaths(basePath: string | undefined) {
  if (!basePath) {
    return false;
  }

  if (!process.env.REACT_APP_API_ENDPOINT) {
    console.log('API endpoint path must be defined');
    return false;
  }

  if (basePath.indexOf(process.env.REACT_APP_API_ENDPOINT) !== 0) {
    return false;
  }
  
  for (const tokenizedAPIPath of privateAPIPaths) {
    if (basePath.indexOf(tokenizedAPIPath) !== -1) {
      return true;
    }
  }

  return false;
}

export default (() => {
    Axios.interceptors.request.use(async (config) => {
    if (isIncludedInPrivateAPIPaths(config.baseURL) && StorageAuth.getSession().idToken) {
      config.headers.Authorization = StorageAuth.getSession().idToken;
    }
  
    return config;
  });

  Axios.interceptors.response.use(async (value) => {
    if (value.config.url === '/login'){
      const response = value.data;
      StorageAuth.saveSession(response.idToken, response.refreshToken, response.expiresIn, true);
    }
      return value;
  });

  Axios.interceptors.response.use(undefined, async (error) => {    
    if (
      error.config
      && error.response
      && error.response.status === 401
      && error.response.data
      && error.response.data.message === 'Token expired'
    ) {
      const Session = StorageAuth.getSession();
      const User = StorageUser.getUser();
      if (!Session.rememberMe || !Session.refreshToken) {
        StorageAuth.removeSession();
        Notification.display('warning', `Session expired. Refresh page and login again.`);

        throw new Axios.Cancel();
      }

      let refreshedSession;
      try {
        const userId: string = User.id ? User.id : '';
        refreshedSession = await new Auth().refreshToken(Session.refreshToken, userId );
      } catch (err) {
        StorageAuth.removeSession();
        Notification.display('error', `Token couldn't be refreshed. Refresh page and login again.`);

        throw new Axios.Cancel();
      }

      StorageAuth.saveSession(refreshedSession.idToken, refreshedSession.refreshToken, refreshedSession.expiresIn, Session.rememberMe);

      return Axios.request(error.config);
    }
  
    throw error;
  });
  
});
