import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'i18next';

// App Routes
import Routes from './Routes';
// Main Class
import './styles/main';

import StorageLanguage from './services/storage/Language';
import { languagesList, languageDefault } from "./utils/Language";


class App extends Component<any> {

  componentDidMount(){
    if (!StorageLanguage.getItem('language')) {
      let userLang = languageDefault.acronym;
      for (let i = 0; i < languagesList.length; i++) {
        if (navigator.language.slice(0, 2) == languagesList[i].acronym){
          userLang = languagesList[i].acronym;
          break;
        }
      }
      StorageLanguage.saveLanguage(userLang);
      i18n.changeLanguage(userLang, () => {
        window.location.reload();
      });
    }
  }

  render() {
    const basename = '/';

    return (
        <BrowserRouter basename={basename}>
            <Routes />
        </BrowserRouter>
    );

  }
}

export default App;
