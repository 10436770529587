import Axios, { AxiosRequestConfig } from 'axios';
import ErrorException from '../exception/ErrorException';
import WarningException from '../exception/WarningException';
import i18next from 'i18next';

export type TGSISAddress = {
  id         : string,
  shipToCode : string,
  phone      : string,
  name       : string,
  street     : string,
  city       : string,
  country    : string,
  zipCode    : string,

  // App properties
  isDeleting : boolean,
};

export type TUser = {
  id                        : string,
  email                     : string,
  name                      : string,
  surname                   : string,
  firebaseId                : string,
  type                      : string,
  role                      : string,
  isEmailVerifiedInFirebase : boolean,
  isAccountVerifiedByAdmin  : boolean,
  company                   : object,
  mobileNumber?             : string | null,
  userAddressesNoGsis       : TGSISAddress[],
  userGsis?                 : {
    discountPercent   : number,
    gsisCode          : string,
    userAddressesGsis : TGSISAddress[],
  },
  createdAt? : Date,
  updatedAt? : string,
  deleteAt?  : string,
};

export type TAddUserAddress = {
  name    : string,
  phone   : string,
  street  : string,
  city    : string,
  country : string,
  zipCode : string,
};

const backendHost = process.env.REACT_APP_API_ENDPOINT;

export default class User {

  public prefix      = '/user';
  private requestConfig : Partial<AxiosRequestConfig> = {};

  constructor() {
    this.requestConfig.baseURL = `${backendHost}${this.prefix}`;
  }

  async userLogin(idToken: string) : Promise<TUser> {
    try {
      this.requestConfig.headers = { Authorization: idToken };
      var result = await Axios.get(`/me`, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new Error(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
          throw new Error(i18next.t('serverError-invalidCredential'));
        default:
          throw new Error(i18next.t('serverError-unknown'));
      }
    }
    return result.data;
  }

  async getUser(id: string) : Promise<TUser> {
    try {
      var result = await Axios.get(`/${id}`, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidCredential'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }

    return result.data;
  }

  async createUser(data: any) : Promise<TUser> {
    try {
      var result = await Axios.post(`/signup`, data, this.requestConfig);
    } catch (err) {
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          // throw new WarningException(i18next.t('serverError-invalidCredential'));
          throw new WarningException(err.response.data.message);
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }

    return result.data;
  }

  async updateUser(data: object) : Promise<TUser> {
    try {
      var result = await Axios.put(`/me`, data, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidCredential'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }    
    return result.data;
  }

  async updatePassword(data: object) : Promise<TUser> {
    try {
      var result = await Axios.put(`/password`, data, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidCredential'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }    
    return result.data;
  }

  async assignGsisCode(gsisCode: string) : Promise<TUser> {
    const data = {
      gsisCode: gsisCode.trim(),
    }

    try {
      var result = await Axios.post(`/gsis-relation`, data, this.requestConfig);
    } catch (err) {
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidSAPCode'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }

    return result.data;
  }
  
  async addUserAddress(data: TAddUserAddress) {
    try {
      var result = await Axios.post(`/address-no-gsis`, data, this.requestConfig);
    } catch (err) {
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }

    return result.data;
  }

  async deleteAddress(addressId: string) {
    try {
      var result = await Axios.delete(`/address-no-gsis/${addressId}`, this.requestConfig);
    } catch (err) {
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }

    return result.data;
  }

}