import React, {useEffect, useState} from 'react';

import CssProvider from '../../providers/cssProvider';
import {allowedSubdomains, currentSubdomain} from '../../utils/Subdomains';

const BaseEmpty: React.FC = ({ children }) => {
    
    const [css, setCss] = useState<any>();

    useEffect(() => {
      let flag = false;
      let obj = {};
      for (let i = 0; i < allowedSubdomains.length; i++) {
        if (currentSubdomain.indexOf(allowedSubdomains[i]) > -1) {
          switch (allowedSubdomains[i]) {
            case 'haierhvac':
							obj = {
                pathImg: '/haier-logo.png',
                brand: 'HAIERHVAC',
                pathCss: '/url_style/haier.css'
							};
              break;
            case "haier":
              obj = {
                pathImg: "/haier-logo.png",
                title: 'Login.TitleHaier',
                brand: 'HAIER',
                pathCss: '/url_style/haier.css'
              };
              break;
            case "hec":
              obj = {
                pathImg: "/hec-logo.png",
                title: 'Login.TitleHec',
                brand: 'HEC',
                pathCss: '/url_style/hec.css'
              };
              break;
            case "airmart":
              obj = {
                pathImg: "/airmart-logo.png",
                title: 'Login.TitleAirmart',
                brand: 'AIRMART',
                pathCss: '/url_style/airmart.css'
              };
              break;
            case "noname":
              obj = {
                pathImg: "/noname-logo.png",
                title: 'Login.TitleNoname',
                brand: 'NONAME',
                pathCss: '/url_style/noname.css'
              };
              break;
            case "wind":
              obj = {
                pathImg: "/wind-ac-logo.png",
                title: 'Login.TitleWind',
                brand: 'WIND',
                pathCss: '/url_style/wind-ac.css'
              };
              break;
            case "all-brand":
              obj = {
                pathImg: "",
                title: '',
                brand: 'ALL-BRAND',
                pathCss: '/url_style/all-brand.css'
              };
              break;
          }
          setCss(obj);
          flag = true;
          break;
        }
      }
      if (!flag) {
        obj = {
          pathImg: "",
          title: "",
          pathCss: '/url_style/all-brand.css'
        };
        setCss(obj);
      }
    }, []);

    return (
        <div className="wrapper">
            <link rel="stylesheet" href={css?.pathCss || ''} />
            <CssProvider.Provider value={[css, setCss]} >
                <section className="section-container-center">
                    {children}
                </section>
            </CssProvider.Provider>
        </div>
    )
};

export default BaseEmpty;
