import Axios, { AxiosRequestConfig } from 'axios';
import ErrorException from '../exception/ErrorException';
import WarningException from '../exception/WarningException';
import i18next from 'i18next';

const backendHost = process.env.REACT_APP_API_ENDPOINT;

type TLogin = {
  idToken      : string,
  refreshToken : string,
  expiresIn    : number
};


export default class Auth {

  private prefix = 'auth';  
  private requestConfig : Partial<AxiosRequestConfig> = {};

  constructor() {
    this.requestConfig.baseURL = `${backendHost}/${this.prefix}`;
  }

  async login(email: string, password: string) : Promise<TLogin> {
    try {
      var result = await Axios.post(`/login`, { email, password }, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new Error(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
          throw new ErrorException(i18next.t('serverError-invalidCredential'));
        case 403:
          throw new WarningException(i18next.t('serverError-emailVerified'));
        default:
          throw new Error(i18next.t('serverError-unknown'));
      }
    }
    
    const { idToken, refreshToken, expiresIn } = result.data;

    return {
      idToken,
      refreshToken,
      expiresIn,
    };
  }

  async refreshToken(refreshTokenString: string, id: string) : Promise<TLogin> {
    try {
      var result = await Axios.post(`/token`, { refreshToken: refreshTokenString, id: id }, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new ErrorException(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
        case 403:
          throw new WarningException(i18next.t('serverError-invalidCredential'));
        default:
          throw new ErrorException(i18next.t('serverError-unknown'));
      }
    }

    const { idToken, refreshToken, expiresIn } = result.data;
    
    return {
      idToken,
      refreshToken,
      expiresIn,
    };
  }

  async verifyEmail(codeValue: string) {
    try {
      var result = await Axios.post(`/verify-email`, { code: codeValue }, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new Error(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
          throw new Error(i18next.t('serverError-invalidCredential'));
        default:
          throw new Error(i18next.t('serverError-unknown'));
      }
    }
    const id = result.data;

    return id;
  }

  async verifyPassword(codeValue: string) {
    try {
      var result = await Axios.post(`/verify-password`, { code: codeValue }, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new Error(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
          throw new Error(i18next.t('serverError-invalidCredential'));
        default:
          throw new Error(i18next.t('serverError-unknown'));
      }
    }
    const id = result.data;

    return id;
  }

  async getForgotPassword(email:string) : Promise<any> {
    try {
      var result = await Axios.get(`/forgot-password/${email}`, this.requestConfig);
    } catch (err) {
      switch (err.response.status) {
        case 403:
          throw new Error(i18next.t('serverError-invalidCredential'));
        default:
          throw new Error(i18next.t('serverError-unknown'));
      }
    }
    
    return result.data;
  }

  async resetPassword(data: object) {
    try {
      var result = await Axios.post(`/reset-password`, data, this.requestConfig);
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw new Error(i18next.t('serverError-connecting'));
      }

      switch (err.response.status) {
        case 401:
          throw new Error(i18next.t('serverError-invalidCredential'));
        default:
          throw new Error(i18next.t('serverError-unknown'));
      }
    }
    const id = result.data;

    return id;
  }


}