const defaultAcronym = 'en';

export enum ELanguages {
    ENGLISH    = 'en',
    SPANISH    = 'es',
    PORTUGUESE = 'pt',
    FRENCH     = 'fr',
    GERMAN     = 'de',
    DUTCH      = 'du',
};

export const languagesList: { acronym: ELanguages, title: string }[] = [
    { acronym: ELanguages.ENGLISH,    title: 'languagesList-en' },
    { acronym: ELanguages.SPANISH,    title: 'languagesList-es' },
    { acronym: ELanguages.PORTUGUESE, title: 'languagesList-pt' },
    { acronym: ELanguages.FRENCH,     title: 'languagesList-fr' },
    { acronym: ELanguages.GERMAN,     title: 'languagesList-de' },
    { acronym: ELanguages.DUTCH,      title: 'languagesList-du' },
];


export const languageDefault:any = languagesList.find( (item:any) => item.acronym === defaultAcronym);