type TKeys = 'name';
type TCompany = {
  name       : string | null
};

export class Company {
  
  public saveCompany(name: string) {
    this.setItem('name', name);
  }

  public removeCompany() {
    this.removeItem('name');
  }

  public getCompany() : TCompany {
    return {
        name: this.getItem('name'),
    };
  }

  private setItem(key: TKeys, value: string) {
    localStorage.setItem(`company.${key}`, value);
  }

  private getItem(key: TKeys) : string | null {
    return localStorage.getItem(`company.${key}`);
  }

  private removeItem(key: TKeys) {
    localStorage.setItem(`company.${key}`, '');
  }

}

export default new Company();
