import { TPieceCart } from "../../types/TPiece";

const key = 'cart.pieces';

export class CartPieces {
  
  public saveList(list: TPieceCart[]) {
    this.setValue(JSON.stringify(list));
  }

  public removeList() {
    this.remove();
  }

  public getCurrentList() {
    let storage = this.getValue();
    let array   = storage === null ? [] : JSON.parse(storage);
    return array;
  }

  public getValue() : string | null {
    return localStorage.getItem(key);
  }

  private remove() {
    localStorage.removeItem(key);
  }

  private setValue(value: string) {
    localStorage.setItem(key, value);
  }

}

export default new CartPieces();
