type TKeys = 'name' | 'surname' | 'id';
type TSession = {
    name       : string | null,
    surname       : string | null,
    id       : string | null,
};

export class User {
  
  public saveUser(name: string, surname: string, id: string) {
    this.setItem('name', name);
    this.setItem('surname', surname);
    this.setItem('id', id);
  }

  public removeUser() {
    this.removeItem('name');
    this.removeItem('surname');
    this.removeItem('id');
  }

  public getUser() : TSession {
    return {
        name: this.getItem('name'),
        surname: this.getItem('surname'),
        id: this.getItem('id'),
    };
  }

  public setItem(key: TKeys, value: string) {
    localStorage.setItem(`user.${key}`, value);
  }

  private getItem(key: TKeys) : string | null {
    return localStorage.getItem(`user.${key}`);
  }

  private removeItem(key: TKeys) {
    localStorage.setItem(`user.${key}`, '');
  }

}

export default new User();
