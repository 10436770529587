import React, {useState, useEffect} from 'react';

import Header from './Header';
import BarMatter from './BarMatter';
import Footer from './Footer';

import ProductProvider from "../../providers/productProvider";
import LanguageProvider from "../../providers/languageProvider";
import OrderProvider from "../../providers/orderProvider";
import PageProvider from "../../providers/pageProvider";
import CssProvider from "../../providers/cssProvider";
import UserProvider from "../../providers/userProvider";

import { languageDefault } from "../../utils/Language";
import HttpStorageI18n from "../../services/storage/Language";
import StorageCartPieces from "../../services/storage/CartPieces";
import UserServiceClass, { TUser } from '../../services/http/User';
import HttpStorageUser from "../../services/storage/User";
import {allowedSubdomains, currentSubdomain} from '../../utils/Subdomains';

import './Base.scss'
import { NotificationContainer } from '../Elements/Notification';

const Base: React.FC = ({ children }) => {

    const [product, setProduct] = useState('');
    const [user, setUser] = useState<TUser>();
    const [page, setPage] = useState({translate: '', code: ''});
    const [currentLanguage, setCurrentLanguage] = useState(HttpStorageI18n.getCurrentLanguage() || languageDefault.acronym);
    const [currentListOrder, setCurrentListOrder] = useState(StorageCartPieces.getCurrentList() || []);
		const [css, setCss] = useState<any>();

    useEffect(()=>{
      let flag = false;
			let obj = {};
      for (let i = 0; i < allowedSubdomains.length; i++) {
        if (currentSubdomain.indexOf(allowedSubdomains[i]) > -1) {
          switch (allowedSubdomains[i]){
            case 'haierhvac':
							obj = {
                pathImg: '/haier-logo.png',
                brand: 'HAIERHVAC',
                pathCss: '/url_style/haier.css'
							};
              break;
            case 'haier':
              obj = {
                pathImg: '/haier-logo.png',
                brand: 'HAIER',
                pathCss: '/url_style/haier.css'
              };							
              break;
            case 'hec':
							obj = {
                pathImg: '/hec-logo.png',
                brand: 'HEC',
                pathCss: '/url_style/hec.css'
							};
              break;
            case 'airmart':
							obj = {
                pathImg: '/airmart-logo.png',
                brand: 'AIRMART',
                pathCss: '/url_style/airmart.css'
							};
              break;
            case 'noname':
							obj = {
                pathImg: '/noname-logo.png',
                brand: 'NONAME',
                pathCss: '/url_style/noname.css'
							};
              break;
            case 'wind':
							obj = {
                pathImg: '/wind-ac-logo.png',
                brand: 'WIND',
                pathCss: '/url_style/wind-ac.css'
							};
              break;
            case 'all-brand':
							obj = {
                pathImg : '',
                brand: 'ALL-BRAND',
                pathCss: '/url_style/all-brand.css'
							};
              break;
					}
					setCss(obj);
          flag = true;
          break;
        }
      }
      if (!flag){
        obj = {
          pathImg : '',
          brand: 'false',
          pathCss: '/url_style/all-brand.css'
        };	
				setCss(obj);
      }
      handleUser();
    },[])

    const handleUser = async () => {
      let userData = await new UserServiceClass().getUser(HttpStorageUser.getUser().id || '');
      if (userData) {
        setUser(userData);
      }
    }

    return (
		<div className="wrapper">
      <link rel="stylesheet" href={css?.pathCss || ''} />
      <NotificationContainer />
			<CssProvider.Provider value={[css, setCss]} >
        <UserProvider.Provider value={[user!, setUser]}>
          { user &&
            <LanguageProvider.Provider value={[currentLanguage, setCurrentLanguage]} >
                <OrderProvider.Provider value={[currentListOrder, setCurrentListOrder]}>
                    <Header />
                    <PageProvider.Provider value={[page, setPage]}>
                        <ProductProvider.Provider value={[product, setProduct]} >
                            <BarMatter />
                            <section className="container-fluid mt-2 body-height">
                              {children}
                            </section>
                            <Footer />
                        </ProductProvider.Provider>
                    </PageProvider.Provider>
                </OrderProvider.Provider>
            </LanguageProvider.Provider>
          }
        </UserProvider.Provider>
			</CssProvider.Provider>
    </div>)
};

export default Base;
